import React from "react";
import H1 from "../../components/h1";
import SEO from "../../components/seo";
import Par from "../../components/Par";

// Download link - Download GESA Information: Gastroscopy (Upper Endoscopy) - http://www.fbrennangastro.com.au/downloads/gesaupperendoscopy.pdf

const title = "Gastroscopy (Upper Endoscopy)";
const Services = () => {
	return (
		<div>
			<SEO
				title={title}
				keywords={[
					title,
					`Gastroenterology services`,
					`Endoscopy services`,
					`Gastroenterologist`,
					`Hepatologist`,
					`Dr Frank`,
					`Dr Brennan`,
					`Dr Spiro`,
					`Dr Raftopoulos`,
					`Dr Ian Yusoff`,
					`Dr Dev Segarajasingam`,
					`Dr Sam Rao`,
					`Dr Duy Pham`,
					`Dr Claire Gordon`,
				]}
				description={title}
			/>
			<H1 text={title} />
			<Par>
				Gastroscopy (upper endoscopy) is a procedure that uses a flexible endoscope to see inside the upper digestive
				tract - including the oesophagus, stomach and the first part of the small intestine.
			</Par>
			<Par>
				During an upper endoscopy a thin flexible tube is carefully fed down the oesophagus into the stomach and the
				small intestine. A small camera on the endoscope transmits an image to a monitor, allowing close examination of
				the intestinal lining.
			</Par>
			<Par>
				It is used to determine the cause of abdominal pain, nausea, vomiting, swallowing difficulties, gastrix reflux,
				unexplained weight loss, anaemia or bleeding in the upper digestive tract.
			</Par>
			<Par>
				Upper endoscopy is also used to remove stuck objects (including food), treat conditions such as bleeding ulcers
				and biopsy tissue. During a biopsy, a small piece of tissue is removed for later examination with a microscope.
			</Par>
		</div>
	);
};

export default Services;
